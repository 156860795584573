/* MEYER */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border-width: 0px;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/** END MEYER */
body {
  background-color: #7404e4;
  /* background-image: url('assets/images/negative-space-abstract-soft-gradient-background.png');
  background-repeat: no-repeat;
  background-size: 100vw 100vh; */
}

.checkmark {
  display: inline-block;
  width: 55px;
  height: 55px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 55px;
  height: 55px;
  background-color: green;
  border-radius: 27px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 8px;
  height: 23px;
  background-color: #fff;
  left: 27px;
  top: 15px;
}

.checkmark_kick {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #fff;
  left: 20px;
  top: 30px;
}

.align-items-start {
  align-items: start;
}

.ctas .primary_button {
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  padding: 17px 24px;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: max-content;
  margin-bottom: 20px;
}

.ctas .primary_button:hover,
.ctas .primary_button:focus {
  background-color: #2d303d;
}

.ctas .primary_button:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

/* Specific styles for GitHub button */
.ctas .github {
  background-color: #000; /* GitHub black */
}

/* Specific styles for Google button */
.ctas .google {
  background-color: #db4437; /* Google red */
}

/* Icons inside buttons */
.ctas .primary_button .button_icon {
  margin-right: 10px; /* Space between icon and text */
  display: flex;
  align-items: center;
}

/* .custom-list {
  list-style-position: outside;
  padding-left: 1em; 
}*/

/*.custom-list li {
  margin-left: 1em; 
  text-indent: -1em; 
} */
/* body {
  margin: 0;
  background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;